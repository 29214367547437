// Colors
$lightBlue: #43A1E366;
$blue: #43A1E3;
$gray: #707070;
$primary-dark: #00095B;
$primary-darkest: #00142E;
$primary: #1700F4;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

// Fonts
$font-styles: (
	regular: 'FordAntenna',
	medium: 'FordAntennaMedium',
	light: 'FordAntennaLight',
	condensed: 'FordAntennaCond',
	condensedMedium: 'FordAntennaCondMed',
);

// FontSize
$default: 16px;

// Layout basics
$max-width: 1440px;
$centered: 0 auto;

.invalid-feedback {
	text-align: left;
	margin-left: 15px !important;
}
