/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/theme.scss";
@import "./assets/fonts/fonts.scss";
@import "./assets/scss/variables.scss";
body {
    font-family: "FordAntenna", Arial;
    background-color: #fff;
}

mat-form-field {
    width: 100%;
}

.btn-ford {
    height: 50px;
    width: 300px;
    color: #fff;
    background-color: #00095b;
    font-family: map-get($font-styles, "light");
    letter-spacing: 1px !important;
    border-radius: 30px !important;
    outline: none;
}

.input-ford {
    border: 1px solid #90a7a9;
    border-radius: 0 !important;
    height: 37px !important;
    padding: 0 15px !important;
    font-family: map-get($font-styles, "medium") !important;
    color: #00095b !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    transition: all .35s !important;
    outline: none;
    &:disabled {
        background-color: #fff !important;
    }
    &:focus {
        box-shadow: 1px 4px 8px 0px gray;
    }
}

.input-ford-label {
    margin-left: 15px !important;
    font-size: 12px !important;
    font-family: map-get($font-styles, "light") !important;
    color: #465968 !important;
}

.input-ford-label.is-invalid {
    color: #fb6340 !important;
}

.invalid-feedback {
    margin-left: 15px !important;
}

.back_container {
    display: flex;
    cursor: pointer;
    &:active {
        outline: none;
    }
    p {
        color: $primary-dark;
        font-family: map-get($font-styles, "regular");
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    mat-icon {
        color: $primary-dark;
    }
}

.popup-base{
  padding: 32px !important;
}

.action-warning{
  justify-content: center;
}

.header-alert{
  padding-bottom: 32px !important;
}

.title-alert{
  margin: 0px !important;
}

.content-alert{
  font-family: "Ford Antenna";
  font-weight: 400 !important;
  font-size: 18px !important;
  justify-content: right !important;
  line-height: 30.9px !important;
  color: #00095b !important;
  padding-bottom: 32px !important;
}

.content-question{
  font-family: "Ford Antenna";
  font-weight: 400 !important;
  font-size: 18px !important;
  justify-content: right !important;
  line-height: 30.9px !important;
  color: #00095b !important;
  text-align: start !important;
  padding: 0 0 32px 0 !important;

  p{
    @extend .content-question;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.action-alert{
  margin: 0px !important;
}

.action-question{
  justify-content: start !important;
  margin: 0px !important;
  gap: 32px;
}

.btn-base{
  padding: 8px  40px !important;
  height: 40px !important;
  border: none;
  font-family: "Ford Antenna" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100px;
  margin: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-base:focus{
  outline: none !important;
  box-shadow: none !important;
}

.btn-base:hover{
  background-image: none !important;
}

.bt-white{
  @extend .btn-base;
  background-color: #FFFFFF !important;
  color: #00095b !important;
  border-radius: 48px !important;
  border: 1px solid #00095b !important;
  width: 100px;
}

.bt-white-106{
  @extend .bt-white;
  width: 106px !important;
}

.bt-blue{
    @extend .btn-base;
    background-color: #00095b !important;
    border-radius: 64px !important;
    width: 100px;
}

.bt-blue-106{
  @extend .bt-blue;
  width: 106px !important;
}

