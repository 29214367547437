
/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */

// note: due to scss import syntax, these urls deliberately do not match the location of this file.
// instead, they need to be formed as a url for where the fonts.scss file is imported.

/* Ford Antenna Regular */
@font-face {
  font-family: 'FordAntenna';
  src: url('../../assets/fonts/Ford-Antenna-Regular/ford-antenna-regular.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Regular/ford-antenna-regular.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Regular/ford-antenna-regular.ttf') format('truetype');
	font-style: normal;
  font-weight: normal;
}

/* Ford Antenna Bold */
@font-face {
  font-family: 'FordAntenna';
  src: url('../../assets/fonts/Ford-Antenna-Bold/ford-antenna-bold.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Bold/ford-antenna-bold.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Bold/ford-antenna-bold.ttf') format('truetype');
	font-style: normal;
  font-weight: bold;
}

/* Ford Antenna Regular Italic */
@font-face {
  font-family: 'FordAntenna';
  src: url('../../assets/fonts/Ford-Antenna-Regular-Italic/ford-antenna-regular-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Regular-Italic/ford-antenna-regular-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Regular-Italic/ford-antenna-regular-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: normal;
}

/* Ford Antenna Bold Italic */
@font-face {
  font-family: 'FordAntenna';
  src: url('../../assets/fonts/Ford-Antenna-Bold-Italic/ford-antenna-bold-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Bold-Italic/ford-antenna-bold-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Bold-Italic/ford-antenna-bold-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: bold;
}

/* Ford Antenna Medium */
@font-face {
  font-family: 'FordAntennaMedium';
  src: url('../../assets/fonts/Ford-Antenna-Medium/ford-antenna-medium.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Medium/ford-antenna-medium.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Medium/ford-antenna-medium.ttf') format('truetype');
	font-style: normal;
  font-weight: normal;
}

/* Ford Antenna Light */
@font-face {
  font-family: 'FordAntennaLight';
  src: url('../../assets/fonts/Ford-Antenna-Light/ford-antenna-light.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Light/ford-antenna-light.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Light/ford-antenna-light.ttf') format('truetype');
	font-style: normal;
  font-weight: normal;
}

/* Ford Antenna Light Italic */
@font-face {
  font-family: 'FordAntennaLight';
  src: url('../../assets/fonts/Ford-Antenna-Light-Italic/ford-antenna-light-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Light-Italic/ford-antenna-light-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Light-Italic/ford-antenna-light-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: normal;
}

/* Ford Antenna Condensed Regular */
@font-face {
  font-family: 'FordAntennaCond';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Regular/ford-antenna-cond-regular.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Regular/ford-antenna-cond-regular.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Regular/ford-antenna-cond-regular.ttf') format('truetype');
	font-style: normal;
  font-weight: normal;
}

/* Ford Antenna Condensed Bold */
@font-face {
  font-family: 'FordAntennaCond';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Bold/ford-antenna-cond-bold.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Bold/ford-antenna-cond-bold.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Bold/ford-antenna-cond-bold.ttf') format('truetype');
	font-style: normal;
  font-weight: bold;
}

/* Ford Antenna Condensed Regular Italic */
@font-face {
  font-family: 'FordAntennaCond';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Regular-Italic/ford-antenna-cond-regular-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Regular-Italic/ford-antenna-cond-regular-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Regular-Italic/ford-antenna-cond-regular-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: normal;
}

/* Ford Antenna Condensed Bold Italic */
@font-face {
  font-family: 'FordAntennaCond';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Bold-Italic/ford-antenna-cond-bold-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Bold-Italic/ford-antenna-cond-bold-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Bold-Italic/ford-antenna-cond-bold-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: bold;
}

/* Ford Antenna Condensed Medium */
@font-face {
  font-family: 'FordAntennaCondMed';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Medium/ford-antenna-cond-medium.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Medium/ford-antenna-cond-medium.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Medium/ford-antenna-cond-medium.ttf') format('truetype');
	font-style: normal;
  font-weight: normal;
}

/* Ford Antenna Condensed Medium Italic*/
@font-face {
  font-family: 'FordAntennaCondMed';
  src: url('../../assets/fonts/Ford-Antenna-Cond-Medium-Italic/ford-antenna-cond-medium-italic.woff2') format('woff2'),
       url('../../assets/fonts/Ford-Antenna-Cond-Medium-Italic/ford-antenna-cond-medium-italic.woff') format('woff'),
       url('../../assets/fonts/Ford-Antenna-Cond-Medium-Italic/ford-antenna-cond-medium-italic.ttf') format('truetype');
	font-style: italic;
  font-weight: normal;
}

/********************************* [END] Ford Fonts *********************************/
